/* global Ladda */
( function( $, w ) {

    function App() {

            // Initialization
        $( 'body' ).addClass( 'javascript' );

        /**
         * Static utility functions.
         */
        var Utility = this.Utility = {

            /**
             * Copies a string to the clipboard.
             *
             * @param {string} value The string to copy
             */
            copyToClipboard: function( value ) {
                var input = $( '<input />' ).appendTo( 'body:first' ).val( value );
                input.get( 0 ).select();
                document.execCommand( 'copy' );
                input.remove();
            },

            /**
             * Formats the given date according to the given PHP format.
             *
             * @param {Date} date The date to format
             * @param {string} format The format to use
             * @returns {string} The formatted date
             */
            formatDate: function( date, format ) {
                if ( typeof date !== 'object' || !( date instanceof Date ) ) {
                    throw new Error( 'Argument 1 of App.Utility.formatDate has to be an instance of Date, \'' +
                        (typeof date) + '\' given.' );
                }
                if ( typeof format !== 'string' ) {
                    throw new Error( 'Argument 2 of App.Utility.formatDate has to be of type string, \'' +
                        (typeof format) + '\' given.' );
                }
                    // JavaScript doesn't support lookbehind, while it supports lookahead.
                    // This is why the string gets reversed beforehand and re-reverse afterwards.
                var reversed = Utility.reverse( format ),
                    year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate(),
                    hours = date.getHours(),
                    minutes = date.getMinutes(),
                    seconds = date.getSeconds();
                reversed = reversed
                    .replace( /Y(?!\\)/g, Utility.reverse( String( year ) ) )
                    .replace( /n(?!\\)/g, Utility.reverse( String( month ) ) )
                    .replace( /m(?!\\)/g, Utility.reverse( Utility.padLeft( month, 2, 0 ) ) )
                    .replace( /j(?!\\)/g, Utility.reverse( String( day ) ) )
                    .replace( /d(?!\\)/g, Utility.reverse( Utility.padLeft( day, 2, 0 ) ) )
                    .replace( /H(?!\\)/g, Utility.reverse( Utility.padLeft( hours, 2, 0 ) ) )
                    .replace( /i(?!\\)/g, Utility.reverse( Utility.padLeft( minutes, 2, 0 ) ) )
                    .replace( /s(?!\\)/g, Utility.reverse( Utility.padLeft( seconds, 2, 0 ) ) );
                return Utility.reverse( reversed );
            },

            /**
             * Formats a date to human readable time.
             *
             * @param {Date} date The date to format
             * @param {boolean} [seconds] Whether to add the seconds to the result
             * @return {string} The formatted time
             */
            getHrTime: function( date, seconds ) {
                var result = Utility.padLeft( date.getHours(), 2, 0 ) + ':' +
                    Utility.padLeft( date.getMinutes(), 2, 0 );
                if ( typeof seconds !== 'undefined' && !!seconds ) {
                    result += ':' + Utility.padLeft( date.getSeconds(), 2, 0 );
                }
                return result;
            },

            /**
             * Returns the overall severity of the given validation results.
             *
             * @param {object} validationResults The validation results the return the severity of
             * @return {number} The overall severity
             */
            getValidationSeverity: function( validationResults ) {
                var severity = 0;
                if ( typeof validationResults !== 'object' || $.isEmptyObject( validationResults ) ) {
                    return severity;
                }
                $.each( validationResults, function( recordId, argumentResults ) {
                    if ( typeof argumentResults !== 'object' || $.isEmptyObject( argumentResults ) ) {
                        return true; // continue
                    }
                    $.each( argumentResults, function( argumentName, results ) {
                        if ( $.type( results ) !== 'array' || results.length < 1 ) {
                            return true; // continue
                        }
                        $.each( results, function( index, result ) {
                            if (
                                typeof result === 'object' &&
                                typeof result.severity === 'number' &&
                                result.severity > severity
                            ) {
                                severity = result.severity;
                            }
                        } );
                    } );
                } );
                return severity;
            },

            /**
             * Inserts HTML code into the DOM.
             *
             * @param {string} html The HTML code to insert
             * @param {object|string} target The target the HTML code is related to
             * @param {string|null|undefined} method The kind of insertion to be processed, defaults to 'appendTo'
             */
            insertDom: function( html, target, method ) {
                if ( typeof html !== 'string' ) {
                    throw new Error( 'Argument 1 of App.Utility.insertDom has to be of type string, \'' +
                        ( typeof html ) + '\' given.' );
                }
                if ( typeof target !== 'string' && typeof target !== 'object' ) {
                    throw new Error( 'Argument 2 of App.Utility.insertDom has to be either of type string ' +
                        'or object, \'' + ( typeof target ) + '\' given.' );
                }
                if ( typeof method !== 'string' ) {
                    method = 'appendTo';
                }
                $( html )[ method ]( target );
            },

            /**
             * Calls a callable.
             *
             * @param {object|function|string} callback The callable to call
             * @param {boolean} callback.traversal Whether to traverse the subject and apply the callable to each item
             */
            invokeCallable: function( callback ) {
                switch ( typeof callback ) {
                    case 'string':
                        w[ callback ]();
                        break;
                    case 'function':
                        callback();
                        break;
                    case 'object':
                        if ( !callback.function ) {
                            throw new Error( 'Unable to invoke callable: no function specified' );
                        }
                        var fn = callback.function,
                            context = callback.namespace ? Utility.resolveNamespace( callback.namespace ) : w,
                            target = callback.target ? $( callback.target ) : null,
                            subject = callback.subject || null,
                            params = callback.params || [];
                        if ( target ) {
                            context = target;
                            if ( callback.scope ) {
                                context = context.data( callback.scope );
                            }
                        }
                        if ( subject && callback.traversal ) {
                            $( subject ).each( function() {
                                context[ fn ].apply( this, params );
                            } );
                        } else {
                            if ( target ) {
                                subject = target;
                            }
                            context[ fn ].apply( subject, params );
                        }
                        break;
                    default:
                        throw new Error( 'Unable to invoke callable from ' + ( typeof callback) );
                }
            },

            /**
             * Checks whether the given value loosely equals to TRUE.
             *
             * @param {*} value The value to check
             * @returns {boolean} Whether the given value loosely equals to TRUE
             */
            isTrue: function( value ) {
                return !!value;
            },

            /**
             * Fills a string to a certain length by adding a character to its left side.
             *
             * @param {string|number} input The string to pad
             * @param {number} padLength The minimum length the string has to have
             * @param {number|string} character The character to prepend
             * @return {*} The padded string
             */
            padLeft: function( input, padLength, character ) {
                if (
                    typeof padLength !== 'number' ||
                    ( typeof input !== 'string' && typeof input !== 'number' ) ||
                    ( typeof character !== 'string' && typeof character !== 'number' ) ||
                    input.length >= padLength
                ) {
                    return input;
                }
                input = String( input );
                character = String( character );
                while ( input.length < padLength ) {
                    input = character + input;
                }
                return input;
            },

            /**
             * Removes an element.
             * Expects 'this' to point to the element to remove.
             */
            remove: function() {
                $( this ).remove();
            },

            /**
             * Returns a variable by its namespace.
             *
             * @param {string} namespace The namespace of the variable to return
             * @return {*} The variable, if any
             */
            resolveNamespace: function( namespace ) {
                var parts = namespace.split( '.' ),
                    variable = w;
                while ( parts.length > 0 ) {
                    var part = parts.shift();
                    if ( !( part in variable ) ) {
                        return /* undefined */;
                    }
                    variable = variable[ part ];
                }
                return variable;
            },

            /**
             * Reverses a sgring.
             *
             * @param {string} value The string to reverse
             * @returns {string} The reversed string
             */
            reverse: function( value ) {
                if ( typeof value !== 'string' ) {
                    throw new Error( 'Argument 1 if App.Utility.reverse has to be of type string, \'' +
                        ( typeof value ) + '\' given.' );
                }
                return value.split( '' ).reverse().join( '' );
            },

            /**
             * Substitutes variable markers within the given content.
             *
             * @param {string} content The content to substitute variable markers in
             * @param {object} variables Name-value-pairs of the variables to substitute
             * @return {string} The substituted content
             */
            substituteMarkers: function( content, variables ) {
                if ( typeof content !== 'string' ) {
                    throw new Error( 'Argument 1 of App.substituteMarkers must be of type string, \'' +
                        ( typeof content ) + '\' given.' );
                }
                if ( typeof variables !== 'object' || $.isEmptyObject( variables ) ) {
                    return content;
                }
                $.each( variables, function( variableName, substitution ) {
                    content = content.replace( new RegExp( '{{\\s*' + variableName + '\\s*}}' ), substitution );
                } );
                return content;
            },

            /**
             * Generates a unique id for the current view.
             *
             * @return {string} The unique id generated
             */
            uniqueId: function() {
                return 'js-' + Math.random().toString( 36 ).substr( 2, 9 );
            }

        };

        /**
         * Allows invoking of functions automatically as soon as the DOM is ready.
         */
        var AutoLoader = this.AutoLoader = new function() {

            /**
             * The queue holding functions to load.
             *
             * @type {object}
             */
            var queue = {};

            /**
             * Adds an function to the queue.
             *
             * @param {string} name The name of the key to set. Used to detect duplicates.
             * @param {function} fn The function to enqueue
             */
            this.register = function( name, fn ) {
                if( typeof fn !== 'function') {
                    throw new Error( 'Second argument of App.AutoLoader.register has to be of type function, \'' +
                        ( typeof fn ) + '\' given.' );
                }
                queue[ String( name ) ] = fn;
            };

            /**
             * Invokes all registered functions.
             */
            function load() {
                $.each( queue, function( name, fn ) {
                    fn();
                } );
            }

            $( document ).ready( load );

        };

        /**
         * Handles the page.
         */
        this.Page = new function() {

            /**
             * Overrides the page title.
             *
             * @param {string} title The new title to set
             */
            this.setTitle = function( title ) {
                document.title = title;
            };

        };

        /**
         * Handles pressed keys.
         */
        this.Keyboard = new function() {

            /**
             * Flags modifiers whether they're active or not.
             *
             * @type {object}
             */
            var modifiers = {
                alt: false,
                command: false,
                ctrl: false,
                shift: false
            };

            /**
             * Checks whether a modifier is active.
             *
             * @param {string} key The key of the modifier to check. If omitted, all modifiers are checked.
             * @return {boolean} Whether the specified modifier is active, or whether any is active, if no key was given
             */
            this.isModifierActive = function( key ) {
                if ( key ) {
                    return !!modifiers[ key ];
                }
                return modifiers.alt || modifiers.command || modifiers.ctrl || modifiers.shift;
            };

            /**
             * Main entry method.
             * Registers events.
             */
            function init() {
                $( document ).on( 'keydown keyup', toggleModifier );
            }

            /**
             * Toggles modifier keys according to the triggered event.
             *
             * @param {object} event jQuery event object
             */
            function toggleModifier( event ) {
                switch ( event.keyCode ) {
                    case 16: // shift
                        modifiers.shift = event.type === 'keydown';
                        break;
                    case 17: // ctrl / strg
                        modifiers.ctrl = event.type === 'keydown';
                        break;
                    case 18: // alt
                        modifiers.alt = event.type === 'keydown';
                        break;
                    case 91: // cmd / windows
                        modifiers.command = event.type === 'keydown';
                }
            }

            AutoLoader.register( 'Keyboard', init );

        };

        /**
         * Handles generic forms.
         */
        this.Form = new function() {

            /**
             * Locks a form input by settings the read-only state.
             * Expects 'this' to point to the input element to lock.
             */
            this.lockInput = function() {
                $( this ).attr( 'readonly', 'readonly' );
            };

            /**
             * Unlocks a form input by removing the read-only state.
             * Expects 'this' to point to the input element to unlock.
             */
            this.unlockInput = function() {
                var textarea = $( this ),
                    value = textarea.val();
                textarea.removeAttr( 'readonly' ).focus().val('').val( value );
            };

            /**
             * Main entry method.
             * Registers events.
             */
            function init() {
                $( 'form textarea[data-auto-height="true"]' ).on( 'input', fitContentHeight ).trigger( 'input' );
            }

            /**
             * Fits the height of a text area according to its contents.
             */
            function fitContentHeight() {
                this.style.height = '';
                this.style.height = this.scrollHeight + 'px';
            }

            AutoLoader.register( 'Form', init );

        };

        /**
         * Tooltip handling.
         */
        this.Tooltip = new function() {

            /**
             * Main entry methods.
             * Initializes tooltips.
             */
            function init() {
                $( '.layout-navbar > .navbar-collapse > .navbar-nav > .nav-item > .nav-link[title]' ).tooltip();
            }

            AutoLoader.register( 'Tooltip', init );

        };

        /**
         * Handles the application request.
         */
        this.Request = new function() {

            var
                /**
                 * The CSRF protection parameter name, if any.
                 *
                 * @type {String|null}
                 */
                csrfParam = null,

                /**
                 * The CSRF protection token, if any.
                 *
                 * @type {String|null}
                 */
                csrfToken = null;

            /**
             * Returns the CSRF protection parameter name.
             *
             * @return {String|null} The CSRF protection parameter name, if any
             */
            this.getCsrfParam = function() {
                if ( csrfParam === null ) {
                    extractCsrfToken();
                }
                return csrfParam || null;
            };

            /**
             * Returns the CSRF protection token.
             *
             * @return {string|null} The CSRF protection token, if any
             */
            this.getCsrfToken = function() {
                if ( csrfToken === null ) {
                    extractCsrfToken();
                }
                return csrfToken || null;
            };

            /**
             * Extracts the CSRF token from meta data.
             */
            function extractCsrfToken() {
                var param = $( 'head > meta[name="csrf-param"]:first' ),
                    token = $( 'head > meta[name="csrf-token"]:first' );
                if ( param.length > 0 && token.length > 0 ) {
                    csrfParam = param.attr( 'content' );
                    csrfToken = token.attr( 'content' );
                }
            }

        };

        /**
         * Function for handling AJAX requests.
         *
         * @param {jQuery} trigger The corresponding trigger element
         */
        var AjaxRequest = this.AjaxRequest = function( trigger ) {

            if ( typeof trigger !== 'object' || !( trigger instanceof jQuery ) ) {
                throw new Error( 'Unable to create new AJAX Request: Illegal context given.' );
            }

                // ensure existence of identifier
            if ( !trigger.attr( 'id' ) ) {
                trigger.attr( 'id', Utility.uniqueId() );
            }

            var
                /**
                 * The context to use for the request, if any.
                 *
                 * @type {jQuery|null}
                 */
                context = null,

                /**
                 * The unique identifier of this request.
                 *
                 * @type {string}
                 */
                requestId = AjaxRequest.createRequestId();

            /**
             * Sends an AJAX request according to the given options.
             *
             * @param {object} options The options to use for building the AJAX request
             */
            this.send = function( options ) {
                if ( typeof options !== 'object' ) {
                    throw new Error( 'Unable to send AJAX Request: Illegal option object given.' );
                }
                if ( context !== null ) {
                    options.context = context;
                }
                trigger.trigger( 'request.app.ajax', [ requestId ] );
                $.ajax( options )
                    .always( emitResponse )
                    .done( handleResponse )
                    .fail( handleError );
            };

            /**
             * Sets the context to use for the request.
             *
             * @param {jQuery} obj The context to use
             */
            this.setContext = function( obj ) {
                if ( typeof obj !== 'object' || !( obj instanceof jQuery ) ) {
                    throw new Error( 'Unable to set AJAX context: Illegal object given.' );
                }
                context = obj;
            };

            /**
             * Triggers an event indicating an AJAX request has finished.
             *
             * @param {*} response The AJAX response received
             */
            function emitResponse( response ) {
                trigger.trigger( 'response.app.ajax', [ response, requestId ] );
            }

            /**
             * Logs an AJAX error and triggers the corresponding event.
             *
             * @param {{responseJSON: *}} response The AJAX response received
             */
            function handleError( response ) {
                trigger.trigger( 'error.app.ajax', [ response, requestId ] );
                if ( console && console.log ) {
                    console.log( response.responseJSON || response );
                }
                if ( ( 'growl' in jQuery ) && ( 'Yii' in w ) ) {
                    jQuery.growl.error( {
                        title: Yii.t( 'roster', 'ajax/error/title' ),
                        message: Yii.t( 'roster', 'ajax/error/general' ),
                        size: 'large'
                    } );
                }
            }

            /**
             * Handles an AJAX response.
             *
             * @param {{callbacks: Array, deletions: Array, insertions: Array, replacements: Array, validation: {}}} response The AJAX response object received
             */
            function handleResponse( response ) {
                trigger.trigger( 'success.app.ajax', [ response, requestId ] );
                    // Validation results
                if ( response.validation && !$.isEmptyObject( response.validation ) ) {
                    trigger.trigger( 'validate.app.ajax', [ response.validation, requestId ] );
                }
                    // Deletions, Replacements, Insertions and Callbacks
                AjaxRequest.handleResponseData(response, this, { trigger: '#' + trigger.attr( 'id' ) } );
            }

        };

        /**
         * Storage of AJAX request identifiers already created.
         *
         * @type {Array}
         * @private
         */
        AjaxRequest._requestIds = [];

        /**
         * Creates a unique AJAX request identifier.
         *
         * @return {string} The AJAX request identifier created
         */
        AjaxRequest.createRequestId = function() {
            var id = 'ajax.request.' + ( AjaxRequest._requestIds.length + 1 );
            AjaxRequest._requestIds.push( id );
            return id;
        };

        /**
         * Handles response data, coming from an AJAX response.
         *
         * @param {object} response The response data to handle
         * @param {jQuery|HTMLElement|null} [target] The context of the AJAX request, if any
         * @param {object|null} [variables] Marker variables to substitute
         */
        AjaxRequest.handleResponseData = function( response, target, variables ) {
            var identifier = target ? $( target ).attr( 'id' ) : null,
                context = identifier ? '#' + identifier : null,
                args = $.extend( ( typeof variables === 'object' ) ? variables : {}, { context: context } );
                // DOM deletions
            if ( response.deletions && response.deletions.length > 0 ) {
                $.each( response.deletions, function() {
                    if ( typeof this.target === 'undefined' ) {
                        throw new Error( 'Unable to delete fragment: No target specified.' );
                    }
                    $( Utility.substituteMarkers( this.target, args ) ).remove();
                } );
            }
                // DOM replacements
            if ( response.replacements && response.replacements.length > 0 ) {
                $.each( response.replacements, function() {
                    if ( typeof this.target === 'undefined' ) {
                        throw new Error( 'Unable to replace fragment: No target specified.' );
                    }
                    if ( typeof this.html !== 'string' ) {
                        throw new Error( 'Unable to replace fragment: No HTML content specified.' );
                    }
                    $( Utility.substituteMarkers( this.target, args ) ).replaceWith( this.html );
                } );
            }
                // DOM insertions
            if ( response.insertions && response.insertions.length > 0 ) {
                $.each( response.insertions, function() {
                    if ( typeof this.target === 'undefined' ) {
                        if ( context !== null ) {
                            this.target = context;
                        } else {
                            throw new Error( 'Unable to insert fragment: No target specified.' );
                        }
                    }
                    Utility.insertDom(
                        this.html,
                        Utility.substituteMarkers( this.target, args ),
                        this.method );
                } );
            }
                // Callbacks
            if ( response.callbacks && response.callbacks.length > 0 ) {
                $.each( response.callbacks, function() {
                    if ( typeof this === 'object' && typeof this.target === 'undefined' && context !== null ) {
                        this.target = context;
                    }
                    if ( typeof this === 'object' ) {
                        if ( typeof this.target === 'string' ) {
                            this.target = Utility.substituteMarkers( this.target, args );
                        }
                        if ( typeof this.subject === 'string') {
                            this.subject = Utility.substituteMarkers( this.subject, args );
                        }
                    }
                    Utility.invokeCallable( this );
                } );
            }
        };

        /**
         * Universal AJAX handler.
         */
        this.Ajax = new function() {

            /**
             * Main entry function.
             * Registers events.
             */
            function init() {
                $( 'body' ).on( 'submit', 'form[data-async="true"]', request )
                    .on( 'click', 'a[href][data-async="true"]', request );
            }

            /**
             * Fires an AJAX request according to the given form.
             * Expects 'this' to point to the form specifying the AJAX request.
             *
             * @param {object} event jQuery event object
             */
            function request( event ) {
                event.preventDefault();
                var trigger = $( this ),
                    isForm = trigger.is( 'form' ),
                    context = trigger.data( 'context' ) || null,
                    options = {
                        type: ( ( isForm ? trigger.attr( 'method' ) : trigger.data( 'request-method' ) ) || 'GET' )
                            .toUpperCase(),
                        url: isForm ? trigger.attr( 'action' ) : trigger.attr( 'href' ),
                        data: isForm ? trigger.serialize() : []
                    },
                    request = new AjaxRequest( trigger );
                if ( typeof context === 'string' ) {
                    request.setContext( $( context ) );
                }
                request.send( options );
            }

            AutoLoader.register( 'Ajax', init );

        };

        /**
         * Handling of modals.
         */
        this.Modal = new function() {

            /**
             * Dismisses the given modal.
             * Expects 'this' to point to the modal to dismiss.
             */
            this.dismiss = function() {
                var modal = this;
                if ( typeof modal === 'object' && modal instanceof String ) {
                    modal = modal.valueOf();
                }
                if ( typeof modal === 'string' || modal instanceof HTMLElement ) {
                    modal = $( modal );
                }
                if ( typeof modal !== 'object' || !( modal instanceof jQuery ) || modal.length < 1 ) {
                    return /* void */;
                }
                if ( !modal.is( '.modal' ) ) {
                    modal = modal.closest( '.modal' );
                }
                modal.modal( 'hide' );
            };

            /**
             * Main entry method.
             * Registers events.
             */
            function init() {
                $( 'body' ).on( 'hidden.bs.modal', '.modal[data-persist="false"]', destroy );
            }

            /**
             * Removes a modal from the DOM.
             * Expects 'this' to point to the modal to remove.
             */
            function destroy() {
                var modal = $( this );
                $( ':input[data-dtp]', modal ).each( removeDatePicker );
                modal.remove();
            }

            /**
             * Removes the date time picker connected to the given element.
             * Expects 'this' to point to the element, connected to the date time picker.
             */
            function removeDatePicker() {
                $( '#' + $( this ).data( 'dtp' ) ).remove();
            }

            AutoLoader.register( 'Modal', init );

        };

        /**
         * Handling of the navigation bar.
         */
        var Navbar = this.Navbar = new function () {

            /**
             * Sets the month (m/Y) for export links.
             *
             * @param {string} month The month to set
             */
            this.setMonth = function( month ) {
                $( '#layout-navbar-collapse a[data-action="export"]' ).attr( 'data-month', month );
            };

            /**
             * Main entry method.
             * Registers events.
             */
            function init() {
                $( '#layout-navbar-collapse a[data-action="export"]' ).on( 'click', passMonth );
            }

            /**
             * Passes the selected month to the actual export action.
             *
             * @param {{preventDefault: function}} event jQuery event object
             */
            function passMonth( event ) {
                var anchor = $( this ),
                    url = anchor.attr( 'href' ),
                    month = anchor.attr( 'data-month' ),
                    delim = url.indexOf( '?' ) > -1 ? '&' : '?';
                if (typeof month === 'string' && month.length > 0) {
                    event.preventDefault();
                    url += delim + 'month=' + month;
                    window.location = url;
                }
            }

            AutoLoader.register( 'Navbar', init );

        };

        /**
         * Handling of the calendar widget.
         */
        this.Calendar = new function() {

            /**
             * Passes the selected date of the calendar to export anchors.
             *
             * @param {{view: {props: {dateProfile: {currentRange: {start: Date}}}}}} data The data passed
             */
            this.provideDate = function( data ) {
                var month = Utility.padLeft(data.view.props.dateProfile.currentRange.start.getMonth() + 1, 2, '0'),
                    year = data.view.props.dateProfile.currentRange.start.getFullYear();
                Navbar.setMonth( month + '/' + year );
            };

            /**
             * Renders a single event.
             *
             * @param {{el: HTMLElement, event: {allDay: boolean, end: Date|null, extendedProps: {description: string, editPermission: boolean, recordId: number, status: number, type: string, user: string}, start: Date}}} data
             *        The information about the event rendered
             */
            this.renderEvent = function( data ) {
                var event = $( data.el );
                event.attr( {
                    'data-allday': data.event.allDay ? 'true' : 'false',
                    'data-description': data.event.extendedProps.description || '',
                    'data-start': data.event.start.getTime(),
                    'data-status': data.event.extendedProps.status || 0,
                    'data-target': '#calendar-event-modal',
                    'data-toggle': 'modal',
                    'data-type': data.event.extendedProps.type,
                    'data-user': data.event.extendedProps.user,
                    'data-record': data.event.extendedProps.recordId,
                    'data-permitted': data.event.extendedProps.editPermission ? 'true' : 'false'
                } );
                if ( data.event.end instanceof Date) {
                    event.attr( 'data-end', data.event.end.getTime() );
                }
            };

            /**
             * Main entry method.
             * Registers events.
             */
            function init() {
                $( '#calendar-event-modal' ).on( 'show.bs.modal', populateEventModal );
            }

            /**
             * Populates an event modal according to the associated event.
             *
             * @param {{relatedTarget: HTMLElement}} event The corresponding event
             */
            function populateEventModal( event ) {
                var source = $( event.relatedTarget ),
                    modal = $( this ),
                    statusBadge = modal.find( '#calendar-event-status' ),
                    status = w.parseInt( source.data( 'status' ) || 0, 10 ),
                    user = source.data( 'user' ) || '',
                    abbr = $.trim( source.text() ).toUpperCase(),
                    type = $.trim( source.data( 'type' ) || '' ),
                    allDay = !!( source.data( 'allday' ) || false ),
                    startTstamp = w.parseInt( source.data( 'start' ), 10 ) || null,
                    start = typeof startTstamp === 'number' ? new Date( startTstamp ) : null,
                    startNode = modal.find( '#calendar-event-start' ),
                    endTstamp = w.parseInt( source.data( 'end' ), 10 ) || null,
                    end = typeof  endTstamp === 'number' ? new Date( endTstamp - ( allDay ? 1000 : 0 ) ) : null,
                    endNode = modal.find( '#calendar-event-end' ),
                    description = $.trim( source.data( 'description' ) || '' ),
                    descriptionContainer = modal.find( '#calendar-event-description-container' ),
                    recordId = w.parseInt( source.data( 'record' ) || 0, 10 ),
                    editPermission = !!( source.data( 'permitted' ) || false ),
                    toolbar = modal.find( '#calendar-event-toolbar' );
                    // approval status
                statusBadge.removeClass( 'badge-outline-success badge-outline-warning' );
                statusBadge.addClass( status === 0 ? 'badge-outline-success' : 'badge-outline-warning' );
                statusBadge.text( Yii.t( 'roster', 'model/holiday/state/' + status ) );
                    // user
                if ( abbr.length > 0 ) {
                    abbr = '(' + abbr + ')';
                }
                modal.find( '#calendar-event-user' ).text( user );
                modal.find( '#calendar-event-abbr' ).text( abbr );
                    // type
                modal.find( '#calendar-event-type' ).text( type );
                    // date
                if (
                    allDay
                    && start !== null
                    && end !== null
                    && start.getFullYear() === end.getFullYear()
                    && start.getMonth() === end.getMonth()
                    && start.getDate() === end.getDate()
                ) {
                    end = null;
                }
                if ( start === null || end === null  ) {
                    modal.find( '#calendar-event-to' ).addClass( 'd-none' );
                    startNode.removeClass( 'text-right' ).addClass( 'text-center' );
                    endNode.removeClass( 'text-left' ).addClass( 'text-center' );
                } else {
                    modal.find( '#calendar-event-to' ).removeClass( 'd-none' );
                    startNode.removeClass( 'text-center' ).addClass( 'text-right' );
                    endNode.removeClass( 'text-center' ).addClass( 'text-left' );
                }
                if ( start !== null ) {
                    startNode.removeClass( 'd-none' );
                    var startsAt = startNode.children( 'time' ),
                        startTime = startsAt.children( '#calendar-event-start-time' );
                    startsAt.children( '#calendar-event-start-date' ).text( Utility.formatDate( start, 'd.m.Y' ) );
                    if ( allDay ) {
                        startsAt.attr( 'datetime', Utility.formatDate( start, 'Y-m-d' ) );
                        startTime.addClass( 'd-none' );
                    } else {
                        startsAt.attr( 'datetime', Utility.formatDate( start, 'Y-m-d H:i' ) );
                        startTime.removeClass( 'd-none' )
                            .children( 'span:first' )
                            .text( Yii.t(
                                'roster',
                                'general/time/hr',
                                { time: Utility.formatDate( start, 'H:i' ) } ) );
                    }
                } else {
                    startNode.addClass( 'd-none' );
                }
                if ( end !== null ) {
                    endNode.removeClass( 'd-none' );
                    var endsAt = endNode.children( 'time' ),
                        endTime = endsAt.children( '#calendar-event-end-time' );
                    endsAt.children( '#calendar-event-end-date' ).text( Utility.formatDate( end, 'd.m.Y' ) );
                    if ( allDay ) {
                        endsAt.attr( 'datetime', Utility.formatDate( end, 'Y-m-d' ) );
                        endTime.addClass( 'd-none' );
                    } else {
                        endsAt.attr( 'datetime', Utility.formatDate( end, 'Y-m-d H:i' ) );
                        endTime.removeClass( 'd-none' )
                            .children( 'span:first' )
                            .text( Yii.t(
                                'roster',
                                'general/time/hr',
                                { time: Utility.formatDate( end, 'H:i' ) } ) );
                    }
                } else {
                    endNode.addClass( 'd-none' );
                }
                    // description
                if ( description.length > 0 ) {
                    descriptionContainer.removeClass( 'd-none' )
                        .find( '#calendar-event-description' )
                        .text( description );
                } else {
                    descriptionContainer.addClass( 'd-none' );
                }
                    // toolbar
                if ( editPermission && recordId > 0 ) {
                    toolbar.removeClass( 'd-none' );
                    var editLink = toolbar.children( '#calendar-event-edit' )[0];
                    editLink.href = editLink.href.replace( '__id__', recordId );
                } else {
                    toolbar.addClass( 'd-none' );
                }
            }

            AutoLoader.register( 'Calendar', init );

        };

    }

    w.App = new App();

} )( jQuery, window );